
import Vue from "vue"
import { mapStores } from "pinia"
import { Context } from "@nuxt/types"
import { MetaInfo } from "vue-meta"
import { TimelineEvent, TimelineInterval, TimelineMarker } from "@evercam/ui"
import { useTimelineStore } from "@/stores/timeline/timeline"
import { useRecordingsStore } from "@/stores/recordings"
import { useSnapshotStore } from "@/stores/snapshots"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { useLayoutStore } from "@/stores/layout"
import { useProjectStore } from "@/stores/project"
import { useCopilotStore } from "@/stores/copilot"
import TimelinePlayer from "@evercam/shared/components/timelinePlayer/TimelinePlayer"
import RightSidebarContent from "@/components/portals/RightSidebarContent"
import TimelinePlayerCommentTooltip from "@evercam/shared/components/timelinePlayer/tooltips/TimelinePlayerCommentTooltip"
import TimelinePlayerMediaHubMilestone from "@evercam/shared/components/timelinePlayer/tooltips/TimelinePlayerMediaHubMilestone"
import { useBimCompareStore } from "@/stores/bimCompare"
import { useConnectorStore } from "@/stores/connector"
import {
  _360UrlParams,
  AnalyticsEvent,
  AnalyticsEventPageId,
  Camera,
  CameraExid,
  CameraStatus,
  CameraStatusLog,
  DroneUrlParams,
  TimelineGroupId,
  TimelineMarkerId,
  TimelineOverlayData,
  TimelineOverlayType,
  TimelinePlayerTooltipItem,
  TimelinePrecision,
  TimelineUrlParams,
  InfoPage,
} from "@evercam/shared/types"
import SnapshotCard from "@evercam/shared/components/SnapshotCard"
import TheTimelineOverlay from "@/components/timeline/TheTimelineOverlay"
import TheTimelineSidebar from "@/components/timeline/TheTimelineSidebar"
import TheTimelineActionButtons from "@/components/timeline/TheTimelineActionButtons"
import { TimelineColors } from "@evercam/shared/constants/timeline"
import {
  clearParamsFromQuery,
  clearQuery,
  debounce,
  extractParamsFromQuery,
} from "@evercam/shared/utils"
import { useCompareStore } from "@/stores/compare"
import { useTimelineAnprGroupStore } from "@/stores/timeline/timelineAnprGroup"
import { useTimeline360GroupStore } from "@/stores/timeline/timeline360Group"
import { useTimelineDroneGroupStore } from "@/stores/timeline/timelineDroneGroup"
import { useTimelineMediaHubGroupStore } from "@/stores/timeline/timelineMediaHubGroup"
import { useTimelineBimGroupStore } from "@/stores/timeline/timelineBimGroup"
import { useTimelineMobileCaptureGroupStore } from "@/stores/timeline/timelineMobileCaptureGroup"
import { useTimelineExNvrGroupStore } from "@/stores/timeline/timelineExNvrGroup"
import { useTimelineCommentsGroupStore } from "@/stores/timeline/timelineCommentsGroup"
import { useTimelineObjectInspectorGroupStore } from "@/stores/timeline/timelineObjectInspectorGroup"
import { useTimelineLuminanceGroupStore } from "@/stores/timeline/timelineLuminanceGroup"
import CameraStatusSnackbar from "@/components/CameraStatusSnackbar.vue"
import ITwinBimCompare from "@/components/iTwin/ITwinBimCompare.vue"
import PlayersActionButtons from "@/components/recordings/player/PlayersActionButtons.vue"
import RecordingsPlayerXrayOverlay from "@/components/recordings/player/RecordingsPlayerXrayOverlay.vue"
import RecordingsPlayerCommentsOverlay from "@/components/recordings/player/RecordingsPlayerCommentsOverlay"
import SnapshotEditor from "@/components/SnapshotEditor.vue"
import ObjectInspectorOverlay from "@evercam/shared/components/ObjectInspectorOverlay.vue"
import CompareExportDialog from "@/components/CompareExportDialog.vue"

export default Vue.extend({
  name: "TheTimeline",
  components: {
    ObjectInspectorOverlay,
    CameraStatusSnackbar,
    PlayersActionButtons,
    RightSidebarContent,
    TimelinePlayer,
    SnapshotCard,
    TimelinePlayerMediaHubMilestone,
    ITwinBimCompare,
    TheTimelineOverlay,
    TheTimelineSidebar,
    TimelinePlayerCommentTooltip,
    RecordingsPlayerXrayOverlay,
    RecordingsPlayerCommentsOverlay,
    SnapshotEditor,
    TheTimelineActionButtons,
    CompareExportDialog,
  },
  middleware: ({ redirect, $permissions }) => {
    if ($permissions.project.has.go()) {
      redirect(`${useProjectStore().projectRoute}/info/${InfoPage.Timeline}`)

      return
    }
  },
  async asyncData({ query }: Context): Promise<void> {
    await useTimelineStore().init(query as TimelineUrlParams)
  },
  meta: {
    pageId: AnalyticsEventPageId.Timeline,
  },
  data() {
    return {
      activeMarkerId: undefined as string | undefined,
      focusedTimestamp: undefined as string | undefined,
      isDraggingMarker: false,
      hideTimeline: false,
      isDOMUpdateScheduled: false,
      isXrayInitialized: false,
      hasUserInteractedWithFilters: false,
      hasUserSelectedADate: false,
      colors: TimelineColors,
      compareMarkerlabelClass: "compare-label-text",
      mediaThumbnailWidth: 64,
      mobileCaptureThumbnailWidth: 120,
      TimelineGroupId,
      pollingIntervalId: 0,
      latestSnapshotTimestamp: "",
      focusedInterval: undefined as TimelineInterval | undefined,
      wasLive: false,
      urlParamsEnums: {
        [TimelineGroupId.Drone]: DroneUrlParams,
        [TimelineGroupId.ThreeSixty]: _360UrlParams,
      },
      groupsEvents: {} as { [groupName: string]: TimelineEvent[] },
      showThumbnails: false,
      precision: null as TimelinePrecision,
      isFetchingEvents: false,
      isInitialized: false,
      editorTranslation: 0,
      imageControlProps: {},
      isActionButtonsMenuHovered: false,
      previousTimestamp: undefined as string | undefined,
    }
  },
  head(): MetaInfo {
    let classList: string[] = []
    if (this.$vuetify.breakpoint.mdAndUp) {
      classList.push("overflow-y-hidden")
    }

    return {
      title: `${this.projectStore.selectedProject?.name} | ${this.$t(
        "pages.timeline"
      )}`,
      meta: [
        { charset: "utf-8" },
        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
      htmlAttrs: {
        class: classList.join(" "),
      },
    } as unknown as MetaInfo
  },
  computed: {
    ...mapStores(
      useTimelineStore,
      useCameraStore,
      useRecordingsStore,
      useSnapshotStore,
      useAccountStore,
      useLayoutStore,
      useProjectStore,
      useBimCompareStore,
      useConnectorStore,
      useTimelineAnprGroupStore,
      useTimeline360GroupStore,
      useTimelineDroneGroupStore,
      useTimelineMediaHubGroupStore,
      useTimelineBimGroupStore,
      useTimelineMobileCaptureGroupStore,
      useTimelineExNvrGroupStore,
      useCompareStore,
      useTimelineCommentsGroupStore,
      useCopilotStore,
      useTimelineObjectInspectorGroupStore,
      useTimelineLuminanceGroupStore
    ),
    hasFixedSidebarContent(): boolean {
      return (
        this.timelineCommentsGroupStore.showComments ||
        this.timelineBimGroupStore.showBim ||
        this.recordingsStore.isXrayActive
      )
    },
    hasExtensibleSidebarContent(): boolean {
      return this.copilotStore.isInRightSideBar
    },
    sidebarStates(): Record<string, boolean> {
      return {
        isFixed: this.hasFixedSidebarContent as boolean,
        isExtensible: this.hasExtensibleSidebarContent as boolean,
      }
    },
    shouldCloseCopilot(): boolean {
      return (
        this.copilotStore.isInRightSideBar &&
        (this.timelineStore.showCompare ||
          this.timelineCommentsGroupStore.showComments ||
          this.timelineBimGroupStore.showBim ||
          this.recordingsStore.isXrayActive)
      )
    },
    shouldCloseComments(): boolean {
      return (
        this.copilotStore.isInRightSideBar ||
        this.timelineStore.showCompare ||
        this.timelineBimGroupStore.showBim ||
        this.recordingsStore.isXrayActive ||
        this.timelineStore.showOverlay
      )
    },
    isAutoPanDisabled(): boolean {
      return (
        this.recordingsStore.isXrayActive ||
        (this.recordingsStore.isLive && !this.hasUserSelectedADate) ||
        this.wasLive
      )
    },
    editorTranslationStyles(): Record<string, unknown> {
      return {
        transform: `translate(0, ${this.editorTranslation}px)`,
      }
    },
    fitMarkersOnChange(): boolean {
      if (this.recordingsStore.isXrayActive) {
        return !this.isXrayInitialized
      }

      if (this.timelineStore.showCompare) {
        return
      }

      return this.hasUserInteractedWithFilters
    },
    compareBeforeSnapshotMarkerTooltip(): TimelinePlayerTooltipItem {
      return {
        title: "Before",
        label: this.getFormattedTimestamp(this.compareStore.beforeTimestamp),
        thumbnailUrl: this.compareStore.snapshot.before,
      }
    },
    compareAfterSnapshotMarkerTooltip(): TimelinePlayerTooltipItem {
      return {
        title: "After",
        label: this.getFormattedTimestamp(this.compareStore.afterTimestamp),
        thumbnailUrl: this.compareStore.snapshot.after,
      }
    },
    currentSnapshotMarkerTooltip(): TimelinePlayerTooltipItem {
      return {
        title: "After",
        label: this.getFormattedTimestamp(
          this.recordingsStore.selectedTimestamp
        ),
        thumbnailUrl: this.recordingsStore.snapshotImgElement?.src,
      }
    },
    olderSnapshotMarkerTooltip(): TimelinePlayerTooltipItem {
      return {
        title: "Before",
        label: this.getFormattedTimestamp(
          this.recordingsStore.selectedXrayTimestamp
        ),
        thumbnailUrl: this.recordingsStore.selectedXraySnapshot,
      }
    },
    tlPlayerClasses(): Record<string, boolean> {
      return {
        [`the-timeline--has-overlay the-timeline--${this.timelineStore.overlayType}`]:
          this.timelineStore.showOverlay,
      }
    },
    playerHeight(): number {
      return this.layoutStore.mainContentRect.height
    },
    playerOverlayHeight(): number {
      return this.hideTimeline
        ? this.layoutStore.mainContentRect.height
        : this.layoutStore.mainContentRect.height -
            this.layoutStore.footerRect.height
    },
    playerWidth(): number {
      const sideBarWidth = this.layoutStore.isRightSidebarVisible
        ? this.layoutStore.rightSidebarWidth
        : 0

      return this.layoutStore.mainContentRect.width - sideBarWidth
    },
    cameraOfflineIntervals(): TimelineInterval[] {
      const cameraExid = this.cameraStore.selectedCamera?.id
      const logs = this.timelineStore.cameraStatusLogs
      if (!cameraExid || !logs?.length) {
        return [] as TimelineInterval[]
      }

      return logs.reduce((acc, statusLog: CameraStatusLog) => {
        if (statusLog.state === CameraStatus.Online) {
          return acc
        }

        return [
          ...acc,
          {
            startDate: new Date(statusLog.start).getTime(),
            endDate: new Date(statusLog.end).getTime(),
          },
        ]
      }, [])
    },
    markers(): TimelineMarker[] {
      if (this.recordingsStore.isEditing) {
        return []
      }

      if (
        this.recordingsStore.isXrayActive &&
        this.recordingsStore.selectedXrayTimestamp
      ) {
        return this.xrayMarkers as TimelineMarker[]
      }

      if (
        this.timelineStore.showCompare &&
        this.compareStore.beforeTimestamp &&
        this.compareStore.afterTimestamp
      ) {
        return this.compareMarkers
      }

      if (
        this.timelineObjectInspectorGroupStore.isActive &&
        this.timelineObjectInspectorGroupStore.showObjectInspector &&
        !this.timelineObjectInspectorGroupStore.isSearching &&
        this.timelineObjectInspectorGroupStore.selectedSegment &&
        this.timelineObjectInspectorGroupStore.firstSeen &&
        this.timelineObjectInspectorGroupStore.lastSeen
      ) {
        return this.objectInspectorMarkers
      }

      return []
    },
    compareMarkers(): TimelineMarker[] {
      return [
        {
          id: TimelineMarkerId.CompareBeforeSnapshot,
          timestamp: this.compareStore.beforeTimestamp,
          maxDate: this.compareStore.afterTimestamp,
          label: "Before snapshot",
          color: this.colors.compareMarker,
          textColor: "#fff",
          isDraggable: true,
          className: "snapshot-marker",
        },
        {
          id: TimelineMarkerId.CompareAfterSnapshot,
          timestamp: this.compareStore.afterTimestamp,
          minDate: this.compareStore.beforeTimestamp,
          label: "After snapshot",
          color: this.colors.compareMarker,
          textColor: "#fff",
          isDraggable: true,
          className: "snapshot-marker",
        },
      ]
    },
    xrayMarkers(): TimelineMarker[] {
      return [
        {
          id: TimelineMarkerId.Xray,
          timestamp: this.recordingsStore.selectedXrayTimestamp,
          maxDate: this.recordingsStore.selectedTimestamp,
          label: "X-Ray snapshot",
          color: this.colors.compareMarker,
          textColor: "#fff",
          isDraggable: true,
          className: "snapshot-marker",
        },
        {
          id: TimelineMarkerId.CurrentSnapshot,
          timestamp: this.recordingsStore.selectedTimestamp,
          minDate: this.recordingsStore.selectedXrayTimestamp,
          label: "Current snapshot",
          color: this.colors.compareMarker,
          textColor: "#fff",
          isDraggable: true,
          className: "snapshot-marker",
        },
      ]
    },
    objectInspectorMarkers(): TimelineMarker[] {
      return [
        {
          id: TimelineMarkerId.ObjectInspectorFirstSeen,
          timestamp: this.timelineObjectInspectorGroupStore.firstSeen,
          label: "First seen",
          color: this.colors.compareMarker,
          textColor: "#fff",
          isDraggable: false,
        },
        {
          id: TimelineMarkerId.ObjectInspectorLastSeen,
          timestamp: this.timelineObjectInspectorGroupStore.lastSeen,
          label: "Last seen",
          color: this.colors.compareMarker,
          textColor: "#fff",
          isDraggable: false,
        },
      ]
    },
    eTimelineProps(): Record<string, unknown> {
      if (
        this.timelineStore.groupsVisibility[TimelineGroupId.ObjectInspector]
      ) {
        return {
          flattenLineGraphEnds: false,
        }
      }

      return {}
    },
  },
  watch: {
    sidebarStates: {
      immediate: true,
      deep: true,
      handler({ isFixed, isExtensible }) {
        if (!isFixed && !isExtensible) {
          this.layoutStore.disableRightSidebar()

          return
        }

        if (isExtensible) {
          this.layoutStore.enableRightSidebar({ isResizable: true, width: 620 })
        } else {
          this.layoutStore.enableRightSidebar()
        }
      },
    },
    shouldCloseCopilot(value) {
      if (!value) {
        return
      }
      this.copilotStore.toggleLayout(!this.hasFixedSidebarContent)
      this.copilotStore.toggleIsCollapsed()
    },
    shouldCloseComments(value) {
      if (value) {
        this.timelineCommentsGroupStore.showComments = false
      }
    },
    "timelineStore.overlayType": {
      handler(value) {
        if (value) {
          this.initOverlayDataFromUrl()
        }
      },
      immediate: true,
    },
    "timelineBimGroupStore.showBim": {
      handler(value) {
        if (value) {
          this.enableBimCompare()
        }
      },
      immediate: true,
    },
    "timelineStore.showCompare": {
      handler(value) {
        if (value) {
          this.enableCompare()
        }
      },
      immediate: true,
    },
    "recordingsStore.isLive": {
      handler(v, old) {
        if (v) {
          this.recordingsStore.selectedTimestamp = new Date().toISOString()
        }
        if (!v && old && this.latestSnapshotTimestamp) {
          this.wasLive = true
          this.recordingsStore.selectedTimestamp = this.latestSnapshotTimestamp
          this.$setTimeout(() => {
            this.wasLive = false
          }, 1000)
        }
      },
      immediate: true,
      deep: true,
    },
    "recordingsStore.isEditing"(v) {
      this.hideTimeline = v
      const translationStart = v ? -this.layoutStore.footerRect.height : 0
      const translationEnd = v ? 0 : -this.layoutStore.footerRect.height
      this.editorTranslation = translationStart
      this.$nextTick(() => {
        this.$setTimeout(() => {
          this.editorTranslation = translationEnd
        })
      })
    },
    "recordingsStore.isXrayActive"(v) {
      if (!v) {
        this.isXrayInitialized = false
      } else {
        this.$setTimeout(() => {
          this.isXrayInitialized = true
        }, 1000)
      }
    },
    "timelineStore.showOverlay": {
      immediate: true,
      handler(v) {
        this.recordingsStore.isPlaying = !v
        if (v) {
          this.wasLive = this.recordingsStore.isLive
          this.recordingsStore.isLive = false

          return
        }

        if (this.wasLive) {
          this.recordingsStore.isLive = true
        }
      },
    },
    "copilotStore.isInRightSideBar"() {
      this.timelineStore.onCopilotVisibilityChange()
    },
  },
  mounted() {
    this.$addEventListener("keydown", this.onKeyHandler)
    this.$addEventListener("keyup", this.onKeyHandler)
    this.$root.$on("copilot-date-clicked", this.onCopilotTimestampClicked)
    this.$root.$on("copilot-camera-mentioned", this.onCopilotCameraMentioned)

    this.$analytics.saveEvent(AnalyticsEvent.PageView)
    if (!this.$permissions.user.is.admin()) {
      this.$posthog.startSessionRecording()
    }
  },
  destroyed() {
    this.layoutStore.disableRightSidebar()
    this.recordingsStore.reset()
    this.compareStore.$reset()
    this.resetTimelineStores()
    clearInterval(this.pollingIntervalId)
    clearQuery()
    if (!this.$permissions.user.is.admin()) {
      this.$posthog.stopSessionRecording()
    }
  },
  methods: {
    onSnapshotEdgeReached() {
      if (this.recordingsStore.isLive) {
        return
      }
      this.timelineStore.updatePlayerSnapshotsInterval()
    },
    onPlaybackToggle(isPlaying) {
      if (isPlaying === this.recordingsStore.isPlaying) {
        return
      }
      this.recordingsStore.isPlaying = isPlaying
      if (isPlaying) {
        this.timelineCommentsGroupStore.visibleComments = []
      } else {
        this.timelineCommentsGroupStore.filterCommentsByInterval(
          this.timelineStore.snapshotsInterval
        )
      }
    },
    onForbiddenTimestampClicked(selectedTimestamp: string) {
      this.$notifications.error({
        text: this.$t("content.etimeline.snapshot_not_found") as string,
        error: this.$t("content.etimeline.snapshot_not_found"),
        notifyParams: {
          ERROR: this.$t("content.etimeline.snapshot_not_found"),
          REQUEST_PAYLOAD: {
            selectedTimestamp,
          },
          FEATURE: "timeline",
        },
      })
    },
    onSnapshotsNotFound(selectedTimestamp: string) {
      if (
        this.previousTimestamp &&
        selectedTimestamp !== this.previousTimestamp
      ) {
        this.timelineStore.selectTimestamp(this.previousTimestamp)
      }
      this.$notifications.warn(
        this.$t("content.etimeline.snapshot_not_found") as string
      )
    },
    onKeyHandler(e) {
      const isCopilotShortcut =
        e.ctrlKey &&
        e.shiftKey &&
        ["k", "K"].includes(e.key) &&
        e.type === "keydown"

      if (!isCopilotShortcut) {
        return
      }

      if (!this.copilotStore.isActive) {
        this.copilotStore.toggleCopilot()
      }

      if (this.copilotStore.isCollapsed) {
        this.copilotStore.toggleIsCollapsed()
      }

      if (this.copilotStore.isFloating) {
        this.copilotStore.toggleLayout()
      }
    },
    resetTimelineStores() {
      this.timelineStore.$reset()
      this.timeline360GroupStore.$reset()
      this.timelineBimGroupStore.$reset()
      this.timelineAnprGroupStore.$reset()
      this.timelineDroneGroupStore.$reset()
      this.timelineExNvrGroupStore.$reset()
      this.timelineMediaHubGroupStore.$reset()
      this.timelineMobileCaptureGroupStore.$reset()
      this.timelineCommentsGroupStore.$reset()
      this.timelineObjectInspectorGroupStore.$reset()
      this.timelineLuminanceGroupStore.$reset()
    },
    enableBimCompare() {
      this.timelineStore.showOverlay = true
      this.timelineStore.overlayType = TimelineGroupId.Bim
    },
    async enableCompare() {
      this.compareStore.$reset()
      this.timelineStore.showOverlay = true
      this.timelineStore.overlayType = "compare"
      await this.timelineStore.updateSnapshotStoreIfNeeded()
      this.compareStore.beforeTimestamp =
        this.snapshotStore.oldestSnapshotTimestamp
      this.compareStore.afterTimestamp =
        this.recordingsStore.selectedTimestamp ||
        this.snapshotStore.latestSnapshotTimestamp
      this.recordingsStore.changeXrayVisibility(false)
    },
    onUserSelectedTimestamp(t: string, previousTimestamp: string) {
      this.previousTimestamp = previousTimestamp
      this.timelineStore.selectTimestamp(t)
      this.hasUserSelectedADate = true
      this.$analytics.saveEvent(AnalyticsEvent.TimelineSelectTimestamp, {
        timestamp: t,
      })
    },
    getFormattedTimestamp(t: string): string {
      return this.$moment
        .tz(t, this.timelineStore.timezone)
        .format("YYYY-MM-DD HH:mm:ss")
    },
    onCameraChange(c: Camera) {
      this.timelineStore.selectCamera(c.id)
    },
    onCopilotCameraMentioned(cameraExid?: CameraExid) {
      if (cameraExid) {
        this.timelineStore.selectCamera(cameraExid)
      }
    },
    onCopilotTimestampClicked(t) {
      this.$analytics.saveEvent(AnalyticsEvent.CopilotClickTimestamp)
      this.goToTimestamp(t)
    },
    onTimelineResized({ contentRect }) {
      this.layoutStore.footerRect = contentRect
    },
    onGroupVisibilityChange({
      groupId,
      value,
    }: {
      groupId: TimelineGroupId
      value: boolean
    }) {
      this.hasUserInteractedWithFilters = true
      const v = !!value

      switch (groupId) {
        case TimelineGroupId.ThreeSixty:
          this.timeline360GroupStore.change360WalksVisibility(v)
          break
        case TimelineGroupId.Drone:
          this.timelineDroneGroupStore.changeDroneFlightsVisibility(v)
          break
        case TimelineGroupId.Bim:
          this.timelineBimGroupStore.changeBimeMilestonesVisibility(v)
          break
        case TimelineGroupId.Media:
          this.timelineMediaHubGroupStore.changeMediaHubVisibility(v)
          break
        case TimelineGroupId.Anpr:
          this.timelineAnprGroupStore.changeAnprVisibility(v)
          break
        case TimelineGroupId.MobileCapture:
          this.timelineMobileCaptureGroupStore.changeMobileCaptureVisibility(v)
          break
        case TimelineGroupId.ExNvrRecordings:
          this.timelineExNvrGroupStore.changeExNvrRecordingsVisibility(v)
          break
        case TimelineGroupId.Comments:
          this.timelineCommentsGroupStore.changeCommentsVisibility(v)
          break
        case TimelineGroupId.Luminance:
          if (v) {
            this.timelineStore.selectCamera(
              this.timelineLuminanceGroupStore.luminanceCamera.exid
            )
            this.disableAllGroups()
          }
          this.timelineLuminanceGroupStore.changeLuminanceVisibility(v)
          break
      }
    },
    onTimelineIntervalChange: debounce(function ({ fromDate, toDate }) {
      if (this.recordingsStore.isLive) {
        return
      }
      this.timelineStore.fromDate = fromDate
      this.timelineStore.toDate = toDate
    }, 500),
    onMarkerDragStart() {
      this.isDraggingMarker = true
    },
    onMarkerDragDrag({
      marker,
      newTimestamp,
    }: {
      marker: TimelineMarker
      newTimestamp: string
    }) {
      if (this.isDOMUpdateScheduled) {
        return
      }
      this.isDOMUpdateScheduled = true
      requestAnimationFrame(() =>
        this.updateCompareMarkerLabel(marker, newTimestamp)
      )
    },
    async onMarkerDragEnd({ marker, newTimestamp }) {
      if (marker.timestamp === newTimestamp) {
        return
      }
      if (marker.id === TimelineMarkerId.CurrentSnapshot) {
        this.onUserSelectedTimestamp(newTimestamp, marker.timestamp)
        this.$analytics.saveEvent(AnalyticsEvent.XrayMoveAfterMarker)
      } else if (marker.id === TimelineMarkerId.Xray) {
        this.$analytics.saveEvent(AnalyticsEvent.XrayMoveBeforeMarker)
        this.recordingsStore.selectedXrayTimestamp = newTimestamp
      } else if (marker.id === TimelineMarkerId.CompareBeforeSnapshot) {
        this.compareStore.beforeTimestamp = newTimestamp
        this.$analytics.saveEvent(
          AnalyticsEvent.TimelineCompareMoveBeforeMarker
        )
      } else if (marker.id === TimelineMarkerId.CompareAfterSnapshot) {
        this.compareStore.afterTimestamp = newTimestamp
        this.$analytics.saveEvent(AnalyticsEvent.TimelineCompareMoveAfterMarker)
      }
    },
    updateCompareMarkerLabel(marker: TimelineMarker, newTimestamp: string) {
      const markersRefs = {
        [TimelineMarkerId.CompareBeforeSnapshot]: "beforeMarker",
        [TimelineMarkerId.CompareAfterSnapshot]: "afterMarker",
        [TimelineMarkerId.CurrentSnapshot]: "snapshotMarker",
        [TimelineMarkerId.Xray]: "xrayMarker",
      }
      const container = (this.$refs?.[markersRefs?.[marker?.id]] as Vue)?.$el

      if (!container) {
        return
      }
      const labelSelector = `.${this.compareMarkerlabelClass}`
      const label = container.querySelector(labelSelector) as HTMLElement

      if (label) {
        label.innerText = this.getFormattedTimestamp(newTimestamp)
      }
      this.isDOMUpdateScheduled = false
    },
    onMilestoneClicked<T extends TimelineOverlayType>({
      milestone,
      milestoneType,
    }: {
      milestone: TimelineOverlayData<T>
      milestoneType: T
    }) {
      if (milestoneType === TimelineGroupId.Comments) {
        this.timelineCommentsGroupStore.selectComment(
          milestone as TimelineOverlayData<TimelineGroupId.Comments>
        )

        return
      }
      this.clearOverlayDataFromUrl()
      this.timelineStore.showOverlay = false
      this.timelineStore.overlayType = milestoneType
      this.timelineStore.overlayData = milestone
      this.$analytics.saveEvent(AnalyticsEvent.TimelineSelectMilestoneItem, {
        milestoneType,
        timestamp: milestone?.timestamp,
      })
      this.$nextTick(() => {
        this.timelineStore.showOverlay = true
      })
    },
    onEventClicked({
      event,
      type,
    }: {
      event: TimelineEvent
      type: TimelineGroupId
    }) {
      let targetTime = event.timestamp

      if ([TimelineGroupId.Anpr].includes(type)) {
        targetTime = this.$moment(event.timestamp)
          .subtract(5, "second")
          .toISOString()
      } else if ([TimelineGroupId.Luminance].includes(type)) {
        targetTime = this.$moment(event.timestamp)
          .subtract(10, "second")
          .toISOString()
      }
      this.goToTimestamp(targetTime)
      this.$analytics.saveEvent(AnalyticsEvent.TimelineClickEvent, {
        timestamp: targetTime,
        type,
      })
    },
    onGroupedEventsClicked({
      events,
      type,
    }: {
      events: TimelineEvent[]
      type: TimelineGroupId
    }) {
      const sortedEvents = [...events].sort(
        (a, b) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      )
      const firstTimestamp = new Date(sortedEvents[0].timestamp).toISOString()
      const startTime = new Date(firstTimestamp).getTime()
      const endTime = new Date(
        sortedEvents[sortedEvents.length - 1].timestamp
      ).getTime()

      const interval = endTime - startTime
      const padding = interval / 10

      const paddedStartDate = new Date(startTime - padding).toISOString()
      const paddedEndDate = new Date(endTime + padding).toISOString()

      this.focusedInterval = {
        startDate: paddedStartDate,
        endDate: paddedEndDate,
      }

      this.$analytics.saveEvent(AnalyticsEvent.TimelineClickEvent, {
        timestamp: firstTimestamp,
        type,
      })
    },
    goToTimestamp(timestamp: string) {
      this.timelineStore.selectTimestamp(timestamp)
    },
    hideOverlay() {
      this.clearOverlayDataFromUrl()
      this.timelineStore.changeCompareVisibility(false)
      this.timelineBimGroupStore.changeBimeMilestonesVisibility(false)
      this.timelineStore.overlayType = null
      this.timelineStore.showOverlay = false
    },
    clearOverlayDataFromUrl() {
      const urlParamsEnum =
        this.urlParamsEnums[this.timelineStore.overlayType] || {}

      if (Object.keys(urlParamsEnum)?.length) {
        clearParamsFromQuery(urlParamsEnum)
      }
    },
    initOverlayDataFromUrl() {
      const urlParamsEnum =
        this.urlParamsEnums[this.timelineStore.overlayType] || {}

      if (Object.keys(urlParamsEnum)?.length) {
        this.timelineStore.overlayData = extractParamsFromQuery(
          urlParamsEnum
        ) as TimelineOverlayData<
          TimelineGroupId.Drone | TimelineGroupId.ThreeSixty
        >
        this.timelineStore.showOverlay = true
      }
    },
    deleteComment(comment) {
      this.timelineCommentsGroupStore.deleteComment(
        this.timelineStore.selectedProject.exid,
        comment.id
      )
      this.timelineCommentsGroupStore.selectedComment = null
    },
    archiveOrUnarchiveComment(comment) {
      this.timelineCommentsGroupStore.archiveOrUnarchiveComment(
        this.timelineStore.selectedProject.exid,
        comment.id,
        !comment.archivedAt
      )
      this.timelineCommentsGroupStore.selectedComment = null
    },
    onTimelineActionButtonsHovered(v) {
      this.isActionButtonsMenuHovered = v
    },
    disableAllGroups() {
      Object.values(TimelineGroupId).forEach((groupId) => {
        this.onGroupVisibilityChange({
          groupId,
          value: false,
        })
      })
    },
  },
})
